/* SupportForm.css */

.support-form-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: BMW_regular;
}

.logoBMW {
    max-width: 90px;
    width: 100%;
}

.name {
    margin-bottom: 10px;
    font-family: BMW_regular;
}

.form-title {
    margin-bottom: 20px;
    font-family: BMW_regular;
}

.support-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the Submit button */
.submit-btn {
    width: 100%;  /* Makes the button as wide as the container */
    height: 50px;  /* Adjusts the height */
    border-radius: 0px;  /* Sharp edges */
    font-family: BMW_regular;
    font-size: 16px;  /* Adjusted for better visibility */
}
