.view-chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 100;
}

.message-list-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
    padding-top: 20px;
}

.message-list {
    display: flex;
    flex-direction: column;
}

.message-item {
    display: flex;
    margin: 10px 0;
}

.message-bubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    background-color: #e0e0e0;
    word-wrap: break-word;
}

.admin-message .message-bubble {
    background-color: #007bff;
    color: #fff;
    margin-left: auto;
}

.user-message .message-bubble {
    background-color: #f1f1f1;
    color: #000;
    margin-right: auto;
}

.message-timestamp {
    font-size: 12px;
    color: #888;
    text-align: right;
    margin-top: 5px;
}

.message-form {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}
