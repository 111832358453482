/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #eee;
  background-color: #222;
}

/* Article container styles */
.article {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

/* Headline styles */
.article h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.article h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

/* Paragraph styles */
.article p {
  margin-bottom: 20px;
  text-align: justify;
  color: #ddd;
}

/* Image styles */
.article img {
  display: block;
  max-width: 100%;
  margin: 20px auto;
}

/* Strong styles */
.article strong {
  font-weight: bold;
  color: #fff;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  /* Body styles */
  body {
    font-size: 14px;
  }

  /* Article container styles */
  .article {
    max-width: none;
    padding: 15px;
  }

  /* Headline styles */
  .article h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .article h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  /* Paragraph styles */
  .article p {
    margin-bottom: 15px;
  }

  /* Image styles */
  .article img {
    margin: 15px 0;
  }
}
