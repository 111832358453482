.message-item {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.user-message {
    justify-content: flex-start;
}

.admin-message {
    justify-content: flex-end;
}

.message-card {
    max-width: 60%;
    word-wrap: break-word;
}

.message-timestamp {
    text-align: right;
    font-size: small;
    color: gray;
    margin-top: 10px;
}

.blue-dot {
    height: 10px;
    width: 10px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

